/* src/App.svelte generated by Svelte v3.38.2 */
import {
	SvelteComponent,
	create_component,
	destroy_component,
	init,
	mount_component,
	noop,
	safe_not_equal,
	transition_in,
	transition_out
} from "../_snowpack/pkg/svelte/internal.js";

import Router, { replace } from "../_snowpack/pkg/svelte-spa-router.js";
import { wrap } from "../_snowpack/pkg/svelte-spa-router/wrap.js";
import Login from "./v1/Login.svelte.js";

function create_fragment(ctx) {
	let router;
	let current;
	router = new Router({ props: { routes: /*routes*/ ctx[0] } });
	router.$on("conditionsFailed", /*conditionsFailed*/ ctx[1]);

	return {
		c() {
			create_component(router.$$.fragment);
		},
		m(target, anchor) {
			mount_component(router, target, anchor);
			current = true;
		},
		p: noop,
		i(local) {
			if (current) return;
			transition_in(router.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(router.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(router, detaching);
		}
	};
}

function instance($$self) {
	var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
		function adopt(value) {
			return value instanceof P
			? value
			: new P(function (resolve) {
						resolve(value);
					});
		}

		return new (P || (P = Promise))(function (resolve, reject) {
				function fulfilled(value) {
					try {
						step(generator.next(value));
					} catch(e) {
						reject(e);
					}
				}

				function rejected(value) {
					try {
						step(generator["throw"](value));
					} catch(e) {
						reject(e);
					}
				}

				function step(result) {
					result.done
					? resolve(result.value)
					: adopt(result.value).then(fulfilled, rejected);
				}

				step((generator = generator.apply(thisArg, _arguments || [])).next());
			});
	};

	

	const routes = {
		"/": Login,
		"/v1/:id/*": wrap({
			asyncComponent: () => import("./v1/Stepper.svelte.js"),
			conditions: [
				_ => __awaiter(void 0, void 0, void 0, function* () {
					const auth = yield import("./services/auth.js"), isLoggedIn = auth.isLoggedIn();
					return isLoggedIn;
				}),
				params => __awaiter(void 0, void 0, void 0, function* () {
					var _a;

					const id = (_a = params.location.match(/^\/v1\/(.+?)\//)) === null || _a === void 0
						? void 0
						: _a[1],
						data = yield import("./v1/services/data.js"),
						record = id && (yield data.loadInvitation(id));

					return !!record;
				})
			], // (params) => {console.log(params); return true}
			
		})
	};

	function conditionsFailed(_event) {
		// console.error('conditionsFailed event', _event.detail);
		replace("/");
	}

	return [routes, conditionsFailed];
}

class App extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, {});
	}
}

export default App;