/* src/shared/Footer.svelte generated by Svelte v3.38.2 */
import {
	SvelteComponent,
	detach,
	element,
	init,
	insert,
	noop,
	safe_not_equal
} from "../../_snowpack/pkg/svelte/internal.js";

function create_fragment(ctx) {
	let footer;

	return {
		c() {
			footer = element("footer");

			footer.innerHTML = `<div class="max-w-7xl mx-auto py-12 px-4 sm:px-6 md:flex md:items-center md:justify-between lg:px-8"><div class="flex justify-center space-x-6 md:order-2"><a href="https://www.evercrowded.com/privacy.html" target="_blank" rel="noreferrer" class="text-base text-gray-500 hover:text-gray-900">Privacy</a> 

      <a href="https://www.evercrowded.com/terms.html" target="_blank" rel="noreferrer" class="text-base text-gray-500 hover:text-gray-900">Terms</a></div> 
    <div class="mt-8 md:mt-0 md:order-1"><p class="text-center text-base text-gray-400">© 2021 EverCrowded, Inc. All rights reserved.</p></div></div>`;
		},
		m(target, anchor) {
			insert(target, footer, anchor);
		},
		p: noop,
		i: noop,
		o: noop,
		d(detaching) {
			if (detaching) detach(footer);
		}
	};
}

class Footer extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, null, create_fragment, safe_not_equal, {});
	}
}

export default Footer;