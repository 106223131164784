
      function getAbsoluteUrl(relativeUrl) {
        const publicPath = __webpack_public_path__;
        let url = '';
        if (!publicPath || publicPath.indexOf('://') < 0) {
          url += window.location.protocol + '//' + window.location.host;
        }
        if (publicPath) {
          url += publicPath;
        } else {
          url += '/';
        }
        return url + relativeUrl;
      }
import * as __SNOWPACK_ENV__ from '../_snowpack/env.js';
({ url: getAbsoluteUrl('dist/index.js'), env: __SNOWPACK_ENV__ }).env = __SNOWPACK_ENV__;

import "./services/firebase.js";
import App from "./App.svelte.js";
import "./index.css";
const app = new App({
  target: document.body
});
export default app;
if (undefined /* [snowpack] ({ url: getAbsoluteUrl('dist/index.js'), env: __SNOWPACK_ENV__ }).hot */ ) {
  undefined /* [snowpack] ({ url: getAbsoluteUrl('dist/index.js'), env: __SNOWPACK_ENV__ }).hot */ .accept();
  undefined /* [snowpack] ({ url: getAbsoluteUrl('dist/index.js'), env: __SNOWPACK_ENV__ }).hot */ .dispose(() => {
    app.$destroy();
  });
}
