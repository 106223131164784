export const environment = {
  firebaseConfig: {
    apiKey: "AIzaSyCiqcs3U0OQ8oZyfl8mrYOnlCiey-fuPis",
    authDomain: "hello-evercrowded-com.firebaseapp.com",
    projectId: "hello-evercrowded-com",
    storageBucket: "hello-evercrowded-com.appspot.com",
    messagingSenderId: "298960855316",
    appId: "1:298960855316:web:cc5248f54b1610db346201",
    measurementId: "G-R0W05CGW2X"
  }
};
