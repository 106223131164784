/* src/v1/Login.svelte generated by Svelte v3.38.2 */
import {
	SvelteComponent,
	action_destroyer,
	append,
	attr,
	component_subscribe,
	create_component,
	destroy_component,
	detach,
	element,
	init,
	insert,
	listen,
	mount_component,
	noop,
	prevent_default,
	run_all,
	safe_not_equal,
	set_data,
	set_style,
	space,
	subscribe,
	text,
	toggle_class,
	transition_in,
	transition_out
} from "../../_snowpack/pkg/svelte/internal.js";

import { onMount } from "../../_snowpack/pkg/svelte.js";
import { push } from "../../_snowpack/pkg/svelte-spa-router.js";
import { formula } from "../../_snowpack/pkg/svelte-formula.js";
import Footer from "../shared/Footer.svelte.js";
import ActionButton from "../shared/ActionButton.svelte.js";
import { analytics } from "../services/firebase.js";

function create_default_slot(ctx) {
	let t;

	return {
		c() {
			t = text("Get in");
		},
		m(target, anchor) {
			insert(target, t, anchor);
		},
		d(detaching) {
			if (detaching) detach(t);
		}
	};
}

function create_fragment(ctx) {
	let t0;
	let div7;
	let div5;
	let div3;
	let div0;
	let t1;
	let main;
	let div2;
	let h1;
	let t5;
	let p0;
	let t7;
	let h20;
	let t9;
	let p1;
	let t11;
	let h21;
	let t13;
	let p2;
	let t16;
	let form_1;
	let div1;
	let input;
	let t17;
	let actionbutton;
	let t18;
	let p3;
	let t19_value = (/*$validity*/ ctx[5].code?.message || /*submittedCodeError*/ ctx[3]) + "";
	let t19;
	let form_action;
	let t20;
	let div4;
	let t21;
	let div6;
	let footer;
	let current;
	let mounted;
	let dispose;

	actionbutton = new ActionButton({
			props: {
				busy: /*busy*/ ctx[2],
				disabled: !/*userInfo*/ ctx[1] || !/*$userInfo*/ ctx[6].loggedIn || /*busy*/ ctx[2] || /*submitted*/ ctx[0] && !/*$isFormValid*/ ctx[4] || !!/*submittedCodeError*/ ctx[3],
				$$slots: { default: [create_default_slot] },
				$$scope: { ctx }
			}
		});

	footer = new Footer({});

	return {
		c() {
			t0 = space();
			div7 = element("div");
			div5 = element("div");
			div3 = element("div");
			div0 = element("div");
			div0.innerHTML = `<img class="h-12 w-auto sm:h-18" src="assets/brandmark-design.svg" alt="Evercrowded"/>`;
			t1 = space();
			main = element("main");
			div2 = element("div");
			h1 = element("h1");

			h1.innerHTML = `<span class="block xl:inline">Customers.</span> 
            <span class="block xl:inline">Delivered.</span>`;

			t5 = space();
			p0 = element("p");
			p0.textContent = "EverCrowded is an innovative advertising tool for restaurant owners. It addresses pain\n            points with the existing methods and effectively provides verifiable results.";
			t7 = space();
			h20 = element("h2");
			h20.textContent = "What is it about";
			t9 = space();
			p1 = element("p");
			p1.textContent = "We use a clever combination of marketing and loyalty tools with technology to help\n            restaurants fill slow days and turn random visitors into regulars. We are preparing a\n            launch in your area and reaching out to get the best restaurants onboard for it.";
			t11 = space();
			h21 = element("h2");
			h21.textContent = "Enter the code";
			t13 = space();
			p2 = element("p");

			p2.innerHTML = `We are currently available in select markets by invitation only. If you received an
            invitation, please enter your code here. If you don’t have one,
            <a class="action" href="https://www.evercrowded.com">join our waiting list to be first to know.</a>`;

			t16 = space();
			form_1 = element("form");
			div1 = element("div");
			input = element("input");
			t17 = space();
			create_component(actionbutton.$$.fragment);
			t18 = space();
			p3 = element("p");
			t19 = text(t19_value);
			t20 = space();
			div4 = element("div");
			div4.innerHTML = `<svg class="hidden md:block absolute left-0 h-full w-16 text-white" fill="currentColor" viewBox="0 0 100 100" preserveAspectRatio="none" aria-hidden="true"><polygon points="0,0 100,0 0,100 0,100"></polygon></svg>`;
			t21 = space();
			div6 = element("div");
			create_component(footer.$$.fragment);
			document.title = "EverCrowded - Innovative advertising for restaurant owners";
			attr(div0, "class", "pt-6 px-4 mx-auto max-w-2xl  sm:px-6 lg:px-8");
			attr(input, "type", "text");
			attr(input, "name", "code");
			input.required = true;
			attr(input, "minlength", "5");
			attr(input, "placeholder", "Enter the code");
			toggle_class(input, "invalid", /*submitted*/ ctx[0] && /*$validity*/ ctx[5].code?.invalid);
			attr(p3, "class", "validation-message");
			form_1.noValidate = true;
			attr(form_1, "class", "style-one-line mt-8");
			toggle_class(form_1, "invalid", /*submitted*/ ctx[0] && !/*$isFormValid*/ ctx[4] || /*submittedCodeError*/ ctx[3]);
			attr(div2, "class", "prose sm:text-center md:text-left");
			attr(main, "class", "mt-10 px-4 pb-8 mx-auto max-w-2xl  sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-8 xl:mt-28");
			attr(div3, "class", "flex-shrink-0 md:w-2/3 lg:w-1/2");
			attr(div4, "class", "h-56 w-full bg-cover bg-center md:h-auto md:relative");
			set_style(div4, "background-image", "url(/assets/k8-sWEpcc0Rm0U-unsplash.jpg)");
			attr(div5, "class", "flex-grow flex flex-col md:flex-row");
			attr(div7, "class", "min-h-screen flex flex-col");
		},
		m(target, anchor) {
			insert(target, t0, anchor);
			insert(target, div7, anchor);
			append(div7, div5);
			append(div5, div3);
			append(div3, div0);
			append(div3, t1);
			append(div3, main);
			append(main, div2);
			append(div2, h1);
			append(div2, t5);
			append(div2, p0);
			append(div2, t7);
			append(div2, h20);
			append(div2, t9);
			append(div2, p1);
			append(div2, t11);
			append(div2, h21);
			append(div2, t13);
			append(div2, p2);
			append(div2, t16);
			append(div2, form_1);
			append(form_1, div1);
			append(div1, input);
			append(div1, t17);
			mount_component(actionbutton, div1, null);
			append(form_1, t18);
			append(form_1, p3);
			append(p3, t19);
			append(div5, t20);
			append(div5, div4);
			append(div7, t21);
			append(div7, div6);
			mount_component(footer, div6, null);
			current = true;

			if (!mounted) {
				dispose = [
					action_destroyer(form_action = /*form*/ ctx[7].call(null, form_1)),
					listen(form_1, "submit", prevent_default(/*submit*/ ctx[12]))
				];

				mounted = true;
			}
		},
		p(ctx, [dirty]) {
			if (dirty & /*submitted, $validity*/ 33) {
				toggle_class(input, "invalid", /*submitted*/ ctx[0] && /*$validity*/ ctx[5].code?.invalid);
			}

			const actionbutton_changes = {};
			if (dirty & /*busy*/ 4) actionbutton_changes.busy = /*busy*/ ctx[2];
			if (dirty & /*userInfo, $userInfo, busy, submitted, $isFormValid, submittedCodeError*/ 95) actionbutton_changes.disabled = !/*userInfo*/ ctx[1] || !/*$userInfo*/ ctx[6].loggedIn || /*busy*/ ctx[2] || /*submitted*/ ctx[0] && !/*$isFormValid*/ ctx[4] || !!/*submittedCodeError*/ ctx[3];

			if (dirty & /*$$scope*/ 262144) {
				actionbutton_changes.$$scope = { dirty, ctx };
			}

			actionbutton.$set(actionbutton_changes);
			if ((!current || dirty & /*$validity, submittedCodeError*/ 40) && t19_value !== (t19_value = (/*$validity*/ ctx[5].code?.message || /*submittedCodeError*/ ctx[3]) + "")) set_data(t19, t19_value);

			if (dirty & /*submitted, $isFormValid, submittedCodeError*/ 25) {
				toggle_class(form_1, "invalid", /*submitted*/ ctx[0] && !/*$isFormValid*/ ctx[4] || /*submittedCodeError*/ ctx[3]);
			}
		},
		i(local) {
			if (current) return;
			transition_in(actionbutton.$$.fragment, local);
			transition_in(footer.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(actionbutton.$$.fragment, local);
			transition_out(footer.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(t0);
			if (detaching) detach(div7);
			destroy_component(actionbutton);
			destroy_component(footer);
			mounted = false;
			run_all(dispose);
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let submittedCodeError;
	let $formValues;
	let $isFormValid;
	let $submitValues;
	let $validity;

	let $userInfo,
		$$unsubscribe_userInfo = noop,
		$$subscribe_userInfo = () => ($$unsubscribe_userInfo(), $$unsubscribe_userInfo = subscribe(userInfo, $$value => $$invalidate(6, $userInfo = $$value)), userInfo);

	$$self.$$.on_destroy.push(() => $$unsubscribe_userInfo());

	var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
		function adopt(value) {
			return value instanceof P
			? value
			: new P(function (resolve) {
						resolve(value);
					});
		}

		return new (P || (P = Promise))(function (resolve, reject) {
				function fulfilled(value) {
					try {
						step(generator.next(value));
					} catch(e) {
						reject(e);
					}
				}

				function rejected(value) {
					try {
						step(generator["throw"](value));
					} catch(e) {
						reject(e);
					}
				}

				function step(result) {
					result.done
					? resolve(result.value)
					: adopt(result.value).then(fulfilled, rejected);
				}

				step((generator = generator.apply(thisArg, _arguments || [])).next());
			});
	};

	
	
	const { form, formValues, submitValues, isFormValid, validity } = formula({});
	component_subscribe($$self, formValues, value => $$invalidate(15, $formValues = value));
	component_subscribe($$self, submitValues, value => $$invalidate(16, $submitValues = value));
	component_subscribe($$self, isFormValid, value => $$invalidate(4, $isFormValid = value));
	component_subscribe($$self, validity, value => $$invalidate(5, $validity = value));
	let userInfo;

	onMount(() => {
		import("../services/auth.js").then(auth => $$subscribe_userInfo($$invalidate(1, userInfo = auth.userInfo)));
	});

	let submitted = false, busy = false, lastSubmittedCode, lastSubmittedCodeError;

	function submit() {
		return __awaiter(this, void 0, void 0, function* () {
			if ($isFormValid) {
				const functions = yield import("../services/functions.js");
				$$invalidate(2, busy = true);

				functions.loginWithInvitationCode({ invitationCode: $submitValues.code }).then(
					success => {
						analytics().setUserId($submitValues.code);
						analytics().setUserProperties({ inviteVersion: success.version });
						push(`/${success.version || "v1"}/${success.id}/`);
					},
					error => {
						$$invalidate(13, lastSubmittedCode = $submitValues.code);

						$$invalidate(14, lastSubmittedCodeError = error === null || error === void 0
						? void 0
						: error.message);

						$$invalidate(0, submitted = true);
						$$invalidate(2, busy = false);
					}
				);
			} else {
				$$invalidate(0, submitted = true);
			}
		});
	}

	$$self.$$.update = () => {
		if ($$self.$$.dirty & /*submitted, $formValues, lastSubmittedCode, lastSubmittedCodeError*/ 57345) {
			$: $$invalidate(3, submittedCodeError = submitted && $formValues.code === lastSubmittedCode
			? lastSubmittedCodeError
			: null);
		}
	};

	return [
		submitted,
		userInfo,
		busy,
		submittedCodeError,
		$isFormValid,
		$validity,
		$userInfo,
		form,
		formValues,
		submitValues,
		isFormValid,
		validity,
		submit,
		lastSubmittedCode,
		lastSubmittedCodeError,
		$formValues
	];
}

class Login extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, {});
	}
}

export default Login;