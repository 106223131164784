/* src/shared/ActionButton.svelte generated by Svelte v3.38.2 */
import {
	SvelteComponent,
	append,
	attr,
	check_outros,
	create_slot,
	detach,
	element,
	group_outros,
	init,
	insert,
	noop,
	safe_not_equal,
	svg_element,
	text,
	toggle_class,
	transition_in,
	transition_out,
	update_slot
} from "../../_snowpack/pkg/svelte/internal.js";

function create_else_block(ctx) {
	let current;
	const default_slot_template = /*#slots*/ ctx[3].default;
	const default_slot = create_slot(default_slot_template, ctx, /*$$scope*/ ctx[2], null);

	return {
		c() {
			if (default_slot) default_slot.c();
		},
		m(target, anchor) {
			if (default_slot) {
				default_slot.m(target, anchor);
			}

			current = true;
		},
		p(ctx, dirty) {
			if (default_slot) {
				if (default_slot.p && (!current || dirty & /*$$scope*/ 4)) {
					update_slot(default_slot, default_slot_template, ctx, /*$$scope*/ ctx[2], dirty, null, null);
				}
			}
		},
		i(local) {
			if (current) return;
			transition_in(default_slot, local);
			current = true;
		},
		o(local) {
			transition_out(default_slot, local);
			current = false;
		},
		d(detaching) {
			if (default_slot) default_slot.d(detaching);
		}
	};
}

// (6:2) {#if busy}
function create_if_block(ctx) {
	let svg;
	let circle;
	let path;
	let t;

	return {
		c() {
			svg = svg_element("svg");
			circle = svg_element("circle");
			path = svg_element("path");
			t = text("\n    Loading...");
			attr(circle, "class", "opacity-25");
			attr(circle, "cx", "12");
			attr(circle, "cy", "12");
			attr(circle, "r", "10");
			attr(circle, "stroke", "currentColor");
			attr(circle, "stroke-width", "4");
			attr(path, "class", "opacity-75");
			attr(path, "fill", "currentColor");
			attr(path, "d", "M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z");
			attr(svg, "class", "animate-spin -ml-1 mr-3 h-5 w-5");
			attr(svg, "xmlns", "http://www.w3.org/2000/svg");
			attr(svg, "fill", "none");
			attr(svg, "viewBox", "0 0 24 24");
		},
		m(target, anchor) {
			insert(target, svg, anchor);
			append(svg, circle);
			append(svg, path);
			insert(target, t, anchor);
		},
		p: noop,
		i: noop,
		o: noop,
		d(detaching) {
			if (detaching) detach(svg);
			if (detaching) detach(t);
		}
	};
}

function create_fragment(ctx) {
	let button;
	let current_block_type_index;
	let if_block;
	let button_disabled_value;
	let current;
	const if_block_creators = [create_if_block, create_else_block];
	const if_blocks = [];

	function select_block_type(ctx, dirty) {
		if (/*busy*/ ctx[1]) return 0;
		return 1;
	}

	current_block_type_index = select_block_type(ctx, -1);
	if_block = if_blocks[current_block_type_index] = if_block_creators[current_block_type_index](ctx);

	return {
		c() {
			button = element("button");
			if_block.c();
			attr(button, "class", "action");
			attr(button, "type", "submit");
			button.disabled = button_disabled_value = /*disabled*/ ctx[0] || /*busy*/ ctx[1];
			toggle_class(button, "busy", /*busy*/ ctx[1]);
		},
		m(target, anchor) {
			insert(target, button, anchor);
			if_blocks[current_block_type_index].m(button, null);
			current = true;
		},
		p(ctx, [dirty]) {
			let previous_block_index = current_block_type_index;
			current_block_type_index = select_block_type(ctx, dirty);

			if (current_block_type_index === previous_block_index) {
				if_blocks[current_block_type_index].p(ctx, dirty);
			} else {
				group_outros();

				transition_out(if_blocks[previous_block_index], 1, 1, () => {
					if_blocks[previous_block_index] = null;
				});

				check_outros();
				if_block = if_blocks[current_block_type_index];

				if (!if_block) {
					if_block = if_blocks[current_block_type_index] = if_block_creators[current_block_type_index](ctx);
					if_block.c();
				} else {
					if_block.p(ctx, dirty);
				}

				transition_in(if_block, 1);
				if_block.m(button, null);
			}

			if (!current || dirty & /*disabled, busy*/ 3 && button_disabled_value !== (button_disabled_value = /*disabled*/ ctx[0] || /*busy*/ ctx[1])) {
				button.disabled = button_disabled_value;
			}

			if (dirty & /*busy*/ 2) {
				toggle_class(button, "busy", /*busy*/ ctx[1]);
			}
		},
		i(local) {
			if (current) return;
			transition_in(if_block);
			current = true;
		},
		o(local) {
			transition_out(if_block);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(button);
			if_blocks[current_block_type_index].d();
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let { $$slots: slots = {}, $$scope } = $$props;
	let { disabled = false } = $$props;
	let { busy = false } = $$props;

	$$self.$$set = $$props => {
		if ("disabled" in $$props) $$invalidate(0, disabled = $$props.disabled);
		if ("busy" in $$props) $$invalidate(1, busy = $$props.busy);
		if ("$$scope" in $$props) $$invalidate(2, $$scope = $$props.$$scope);
	};

	return [disabled, busy, $$scope, slots];
}

class ActionButton extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, { disabled: 0, busy: 1 });
	}
}

export default ActionButton;