import {environment} from "../environment.js";
import firebase from "../../_snowpack/pkg/firebase/app.js";
import "../../_snowpack/pkg/firebase/analytics.js";
import "../../_snowpack/pkg/firebase/performance.js";
export const fb = !firebase.apps.length ? firebase.initializeApp(environment.firebaseConfig) : firebase.app();
const _analytics = firebase.analytics();
export function analytics() {
  return _analytics;
}
const _performance = firebase.performance();
export function performance() {
  return _performance;
}
